import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/footercenterint.module.scss"
class Footerlinksint extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {},language: {}});
  }
  componentDidMount(){
    this.props.data.then(data => {
      this.setState({data: data});
      this.setState({language: this.props.language});
    });
  }
  render() {
    return (
        <div id = {Styles.footerstyles}>
          {(() => {
            switch (this.state.language) {
              case 'de':
                return <footer className="footer">
                    <div className = {Styles.br}>
                        Tightrope Interactive |
                        <a href="/terms-of-service" target="_blank" >Bedingungen |  </a>

                      <a href="/privacy-policy" target="_blank" >Datenschutz |  </a>

                        <a href="/contact-us" target="_blank" >Kontakt |  </a>

                        <a href="/uninstall" target="_blank" >Deinstallieren |  </a>

                        <a href="/unsubscribe" target="_blank" >Abbestellen  </a><br/>

                        <a href="/privacy-policy/#cali-consumer" target="_blank" >Verkaufen Sie meine persönlichen Daten nicht</a> |
                        <span>Warenzeichen sind Eigentum ihrer jeweiligen Inhaber</span>

                    </div>
                </footer>;
                break;
                case 'fr':
                  return <footer className="footer">
                      <div className = {Styles.br}>

                          Tightrope Interactive |
                          <a href="/terms-of-service" target="_blank" > Termes |  </a>

                        <a href="/privacy-policy" target="_blank" >Confidentialité |  </a>

                          <a href="/contact-us" target="_blank" >Contactez-nous |  </a>

                          <a href="/uninstall" target="_blank" >Désinstaller |  </a>

                          <a href="/unsubscribe" target="_blank" >Se Désabonner </a><br/>

                          <a href="/privacy-policy/#cali-consumer" target="_blank" >Ne vendez pas mes informations personnelles </a> |
                          <span> Toutes les marques déposées sont la propriété de leurs propriétaires respectifs</span>
                          </div>

                  </footer>;
                  break;
              default:
                return   <footer className="footer">
                      <div className = {Styles.br}>

                        Tightrope Interactive |
                          <a href="/terms-of-service" target="_blank" >Terms of Service  |  </a>
                          <a href="/privacy-policy" target="_blank" >Privacy Policy |  </a>
                          <a href="/contact-us" target="_blank" >Contact |  </a>
                          <a href="/uninstall" target="_blank" >Uninstall |  </a>
                          <a href="/unsubscribe" target="_blank" >Unsubscribe   </a><br/>
                          <a href="/privacy-policy/#cali-consumer" target="_blank" >Do Not Sell My Personal Information</a> |
                          <span>All trademarks are property of their respective owners</span>
                          </div>

                  </footer>;
            }
          })()}
        </div>
    )
  }
}
export default Footerlinksint
